<template>
  <div>
    <p v-b-toggle.prix-cession-sidebar class="gavc-p gavc-text-tory">
      <i class="gavc-icon gavc-icon-bulb"></i> Comment je calcule mon prix de cession ?
    </p>
    <b-sidebar id="prix-cession-sidebar" right shadow backdrop>
      <div class="px-3 py-2">
        <p class="gavc-h3 gavc-text-tory">
          Votre prix de cession
        </p>
        <p class="gavc-p">
          À ce stade du parcours, le prix de cession que vous proposez est une estimation et <strong>n’est pas contractuellement engageante.</strong>
        </p>
        <p class="gavc-p">
          Pour vous aider à estimer la valeur de votre entreprise, vous pouvez utiliser :
          <ul class="mt-3">
            <li style="padding-bottom: 10px;">
              <strong>La valeur des actifs nets</strong>
              du dernier exercice comptable c’est-à-dire la différence entre le total des immobilisations corporelles et des dettes ;
              <br>
            </li>
            <li style="padding-bottom: 10px;"><strong>La rentabilité potentielle</strong> de votre entreprise en fonction de l’estimation des flux de trésorerie ;</li>
            <li>
              <strong>Le coefficient multiplicateur</strong> de votre secteur d’activité, souvent exprimé par le rapport entre les capitaux propres et
              le chiffre d’affaires ou le résultat d’exploitation.
            </li>
          </ul>
        </p>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: 'helpingPrixCessionSidebar',
}
</script>
